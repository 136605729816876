import React from 'react';
import Anime, { anime } from 'react-animejs-wrapper';
import logo from './whipsong-blue-adjust-transp.png';
import './App.css';

function App() {
  const footerLinks = [
    { url: 'https://www.instagram.com/whipsong/', name: 'instagram' },
    { url: 'https://twitter.com/whipsongmusic', name: 'twitter' },
    {
      url: 'https://www.youtube.com/channel/UCvQYwyQGdMHKo7uCZ9uX1bg',
      name: 'youtube',
    },
    { url: 'https://yebot.net', name: 'yebot.net' },
    // { url: 'http://oilyrecords.com', name: 'oilyrecords.com', },
  ];
  return (
    <div className='App'>
      <Anime
        config={{
          translateY: [-100, 0],
          scale: [0, 1],
          loop: false,
          delay: anime.stagger(200, { start: 500 }),
        }}>
        <header className='App-header'>
          <img
            src={logo}
            className='whipsong-logo anim'
            alt='Whipsong Music Logo'
          />
        </header>
        <h1 className='anim'>Whipsong Music</h1>
        <h3 className='anim'>Production Music</h3>
        <p className='anim'>
          {footerLinks.map(({ url, name }) => {
            return (
              <>
                <a href={url}>{name}</a>&nbsp;
              </>
            );
          })}
        </p>
      </Anime>
    </div>
  );
}

export default App;
